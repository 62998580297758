*,
:before,
:after {
  box-sizing: border-box;
}

// HT https://piccalil.li/quick-tip/add-scroll-margin-to-all-elements-which-can-be-targeted

[id] {
  scroll-margin-top: 2ex;
}

:target {
  text-decoration: underline;
  text-decoration-thickness: 0.5ex;
  text-decoration-color: var(--yellow);
  text-decoration-skip-ink: none;
  text-underline-offset: -0.2ex;
}

@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}

html {
  --text-color: #{$text-color};
  --text-color--darker: #{$text-color--darker};
  --text-color--lighter: #{$text-color--lighter};
  --black: #{$black};
  --white: #{$white};
  --light-grey: #{$light-grey};
  --dark-grey: #{$dark-grey};
  --purple: #{$purple};
  --paper-white: #{$paper-white};
  --yellow: #{$yellow};
}

html {
  // theme vars
  --bg-color: var(--white);
  --link-color: var(--yellow);
}

html,
body {
  height: 100%;
}

body {
  background-color: var(--purple);
  border-top: 0.5rem solid var(--purple);
  color: var(--text-color);
  display: flex;
  flex-direction: column;
}

main {
  position: relative;
  z-index: 1;
  background: var(--bg-color);
  padding: 3rem 0;
  flex-grow: 1;
}

iframe {
  display: block;
}

form {
  padding-bottom: 1.25em;
}

label {
  display: block;
  margin-bottom: 0.5em;
}

input,
textarea {
  width: 100%;
  padding: 1em;
  font-size: 0.8em;
  height: 100%;
  border: 1px solid var(--text-color);
}

textarea {
  height: 10em;
}

a {
  color: var(--text-color);
  text-decoration: none;
  border-bottom: 2px solid var(--yellow);

  &:hover {
    color: var(--text-color--darker);
    border-color: inherit;
    transition: 0.2s ease-in;
  }
}

ul {
  padding: 0 1em;
}

img {
  max-width: 100%;
  object-fit: cover;
}

figure {
  margin: inherit;
}

figcaption {
  font-size: 0.9em;
  font-style: italic;
}

svg {
  fill: currentColor;
}

.highlight {
  pre {
    overflow: auto;
    font-size: 0.8em;
    padding: 1rem;
    background: var(--light-grey);
  }
}
