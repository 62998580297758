.layout {
  display: grid;
  grid-template-columns: $primary-grid;
  @include bp(small) {
    column-gap: $grid-gutter;
  }
}

.title,
article,
.content-main,
.archive-item,
.post-nav {
  grid-column: full-content;
  @include bp(large) {
    grid-column: main-content / 10;
  }
}
