footer {
  background: var(--purple);
  color: var(--white);
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;

  nav {
    grid-column: full-content;
    grid-row: 1;
    font-size: 0.8em;
    padding: 1rem 0;

    ul {
      display: flex;
      flex-flow: wrap;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin-right: 0.5rem;
        a {
          color: var(--white);
          border-bottom: inherit;
          &:hover {
            color: var(--paper-white);
          }
        }
      }
    }
  }
}
