body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.8;
}

p {
  margin-bottom: 1.15rem;
}

code {
  font-family: monospace;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 2.75rem 0 1.05rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

h1 {
  margin-top: 0;
  font-size: 1em;
}

h2 {
  font-size: 1em;
  a {
    border: inherit;
  }
}

h3 {
  font-size: 1em;
}
