.archive-item {
  margin-bottom: 1.5em;

  h2 {
    margin-top: inherit;
    margin-bottom: 0;
  }

  p {
    margin-top: inherit;
  }
}

.index {
  h1 {
    @include bp(medium) {
      margin-top: 2.75rem;
    }
  }

  .content-main {
    grid-column: full-content;
    @include bp(medium) {
      grid-column: full-content / 8;
    }
  }
}

footer.index {
  background: var(--bg-color);
  color: var(--text-color);
  font-size: 0.75em;
  position: inherit;

  p {
    grid-column: full-content;
  }
}

.terms {
  font-size: 0.75em;
  font-style: italic;
}