.post-meta {
  font-size: 0.75em;
  margin-top: 1.5em;
  display: block;

  time {
    a {
      border-bottom: inherit;
    }
  }
}
